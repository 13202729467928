// src/routes/protectedRoutes.tsx

import PrivateRoute from './PrivateRoute';
import { 
    DreDashboardPage, 
    CompanyListPage, 
    ImportTrialBalancetePage, 
    Dashboard, 
    UserListPage,
    DrePage,
    CreateDeParaPage,
    ViewDeParaPage,
    EditDeParaPage, 
    BalancoPage,
    ImportlBalanceteNormalPage
} from './lazyComponents';

export const protectedRoutes = [
    {
        path: '/dashboards',
        name: 'Dashboards',
        icon: 'ri-bar-chart-line',
        header: 'Custom',
        children: [
            {
                path: '/dashboards/analise-gerenciais',  
                name: 'Análise Gerenciais',
                element: <DreDashboardPage />,
                route: PrivateRoute,
            },
        ],
    },
    {
        path: '/empresas',
        name: 'Empresas',
        icon: 'ri-building-line',
        header: 'Custom',
        children: [
            {
                path: '/empresas/lista-de-empresas',
                name: 'Lista de Empresas',
                element: <CompanyListPage />,
                route: PrivateRoute,
            },
        ],
    },
    {
        path: '/reports',
        name: 'Relatórios',
        icon: 'ri-building-line',
        header: 'Custom',
        children: [
            {
                path: '/reports/dre',
                name: 'Demonstração Resultado do Exercício',
                element: <DrePage />,
                route: PrivateRoute,
            },
            {
                path: '/reports/balanco',
                name: 'Balanço Patrimonial',
                element: <BalancoPage />,
                route: PrivateRoute,
            },
        ],
    },
    {
        path: '/uploads-de-arquivos',
        name: 'Uploads',
        icon: 'ri-file-upload-line',
        header: 'Custom',
        children: [
            {
                path: '/uploads-de-arquivos/importar-balancetes',
                name: 'Importar Balancetes',
                element: <ImportTrialBalancetePage />,
                route: PrivateRoute,
            },
            {
                path: '/uploads-de-arquivos/importar-balancetes-empresas',
                name: 'Importar Balancetes',
                element: <ImportlBalanceteNormalPage />,
                route: PrivateRoute,
            },
            {
                path: '/uploads-de-arquivos/planilha-padrao',
                name: 'Planilha Padrão',
                element: <ImportTrialBalancetePage />,
                route: PrivateRoute,
            },
            {
                path: '/uploads-de-arquivos/importar-balancetes/cadastrar-balancete',
                name: 'Cadastrar Balancete', 
                element: <CreateDeParaPage />,
                route: PrivateRoute,
            },
            {
                path: '/uploads-de-arquivos/visualizar-balancete/:id',
                name: 'Editar Balancete', 
                element: <ViewDeParaPage />,
                route: PrivateRoute,
            },
            {
                path: '/uploads-de-arquivos/editar-depara/:id', 
                name: 'Continuar De/Para', 
                element: <EditDeParaPage />,
                route: PrivateRoute,
            },
        ],
    },
    {
        path: '/admin',
        name: 'Dashboards',
        icon: 'home',
        header: 'Navigation',
        children: [
            {
                path: '/',
                name: 'Root',
                element: <Dashboard />,
                route: PrivateRoute,
            },
            {
                path: '/dashboard',
                name: 'Dashboard',
                element: <Dashboard />,
                route: PrivateRoute,
            },
        ],
    },
    {
        path: '/configuracoes',
        name: 'Configurações',
        icon: 'ri-settings-line',
        header: 'Configurações',
        children: [
            {
                path: '/configuracoes/usuarios',
                name: 'Usuários',
                element: <UserListPage />,
                route: PrivateRoute,
            },
        ],
    },
];
