// src/routes/lazyComponents.tsx

import React from 'react';

export const DreDashboardPage = React.lazy(() => import('@/pages/AnaliseGerenciais/index'));
export const CompanyListPage = React.lazy(() => import('@/pages/Companies/index'));
export const ImportTrialBalancetePage = React.lazy(() => import('@/pages/ImportTrialBalancete/index'));
export const ImportlBalanceteNormalPage = React.lazy(() => import('@/pages/importBalancete/index'));
export const CreateDeParaPage = React.lazy(() => import('@/pages/ImportTrialBalancete/CreateDeParaPage'));
export const ViewDeParaPage = React.lazy(() => import('@/pages/ImportTrialBalancete/ViewDeParaPage'));
export const EditDeParaPage = React.lazy(() => import('@/pages/ImportTrialBalancete/EditDeParaPage')); // Nova linha
export const Dashboard = React.lazy(() => import('@/pages/Dashboards'));
export const UserListPage = React.lazy(() => import('@/pages/Users/index'));
export const DrePage = React.lazy(() => import('@/pages/DRE'));
export const BalancoPage = React.lazy(() => import('@/pages/Balanco'));
